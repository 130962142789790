import { tagMocks } from 'shared/mocks';

import { LinkButtonMock } from 'common/Button';
import { ImageDefaultPropsMock, ImageStructureMock } from 'common/Image';
import { CardVariants } from 'components/Card/models.d';

export const CardDefaultClassesMock = ['card', 'card__product', 'background--rajah'];

export const CardDefaultPropsMock = {
  title: 'GEL QUOTIDIEN INTIME RÉGULATEUR ACTIVE',
  image: {
    imageStructure: ImageDefaultPropsMock,
  },
  button: LinkButtonMock,
  variant: CardVariants.PRODUCT,
  color: 'rajah',
  url: '/test-url',
  description: 'Spécialiste de l’hygiène intime, Intima a créé pour vous le.',
};

export const CardBrandPropsMock = {
  id: '31a6ff15-f42f-5f55-98a5-061b65f93a6f',
  card: {
    title: 'GEL QUOTIDIEN INTIME RÉGULATEUR ACTIVE',
    image: {
      imageStructure: ImageDefaultPropsMock,
    },
    button: LinkButtonMock,
    variant: CardVariants.BRAND,
    color: 'rajah',
    url: '/test-url',
  },
  order: '1',
  tags: [tagMocks],
  url: '/our-products/senokot-max-strength-tablets/',
};

export const CardMock = {
  id: '31a6ff15-f42f-5f55-98a5-061b65f93a6f',
  card: {
    title: 'Senokot Max Strenght Tablets',
    description:
      '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>',
    variant: CardVariants.PRODUCT,
    color: 'lime',
    button: LinkButtonMock,
    image: ImageStructureMock,
  },
  tags: [tagMocks],
  url: '/our-products/senokot-max-strength-tablets/',
};
