import React, { FC, useRef } from 'react';
import { browserName } from 'react-device-detect';
import { animated, config, useSpring } from 'react-spring';
import { navigate } from '@reach/router';
import classNames from 'classnames';

import Button from 'common/Button';
import Image from 'common/Image';
import Link from 'common/Link';
import Typography from 'common/Typography';

import { handleCardTitleProps } from './utils';

import { CardProps, CardVariants } from './models.d';

import './Card.scss';

const Card: FC<CardProps> = ({
  image,
  title,
  description,
  descriptionConditional,
  button,
  url,
  variant = CardVariants.ARTICLE_SMALL,
  color = 'color',
  className,
}) => {
  const cardItem = useRef<HTMLDivElement>(null!);
  const isSafari = browserName.toLowerCase() === 'safari';

  const isBannerVariant = variant === CardVariants.ARTICLE_BANNER;

  const calc = (e) => {
    const { current } = cardItem;

    if (current !== null) {
      return [
        -(
          ((e.clientY - current.getBoundingClientRect().top - current.offsetHeight / 2) /
            current.offsetHeight) *
          2
        ),
        ((e.clientX - current.getBoundingClientRect().left - current.offsetWidth / 2) /
          current.offsetWidth) *
          2,
        1.02,
      ];
    }

    return null;
  };

  const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

  const [props, setSpring] = useSpring(() => ({ xys: [0, 0, 1], config: config.default }));

  const cardClasses = classNames(
    'card',
    {
      [`card__${variant?.toLowerCase()}`]: variant,
      [`background--${color}`]: color,
    },
    className
  );

  const isProductVariant = variant === CardVariants.PRODUCT;
  const isCupVariant = variant === CardVariants.CUP;

  const cardTitleProps = handleCardTitleProps(variant);

  return (
    <animated.div
      className={cardClasses}
      data-testid="card-item"
      onClick={(event) => {
        if (event.ctrlKey) {
          window.open(url);
        } else {
          navigate(url);
        }
      }}
      onMouseMove={(e) => setSpring.start({ xys: calc(e) })}
      onMouseLeave={() => setSpring.start({ xys: [0, 0, 1] })}
      style={
        !isSafari && !isBannerVariant
          ? {
              transform: props.xys.to(trans),
            }
          : { transform: 'none' }
      }
      ref={cardItem}
    >
      <div className="card__link card__link--image">
        {image ? (
          <Image
            {...image.imageStructure}
            objectFit={isProductVariant || isCupVariant ? 'contain' : 'cover'}
            className="card__image"
          />
        ) : null}
      </div>
      <div className="card__content">
        <Link
          className="card__title"
          as="h2"
          url={url}
          ariaLabel={title.replace(/(<([^>]+)>)/gi, '')}
          {...cardTitleProps}
          lineHeight="small"
          dangerouslySetInnerHTML={
            typeof title === 'string' ? title.replaceAll(' ?', '&nbsp;?') : title
          }
        />
        {description ? (
          <Typography
            dangerouslySetInnerHTML={descriptionConditional || description}
            size={isProductVariant || isCupVariant ? 40 : { base: 16, md: 20 }}
            padding={{ top: { base: 'md', md: 'unset' }, bottom: { base: 'sm' } }}
            lineHeight={isProductVariant || isCupVariant ? 'normal' : 'small'}
            className="card__description"
            color="mine-shaft"
            weight={isProductVariant || isCupVariant ? 'semi-bold' : 'regular'}
          />
        ) : null}

        {button ? (
          <Button
            {...button}
            key={button.ariaLabel}
            url="#"
            ariaLabel={isProductVariant ? button.ariaLabel : title}
            className="card__button"
          >
            {button?.label}
          </Button>
        ) : null}
      </div>
    </animated.div>
  );
};

export default Card;
