import { TypographyProps } from 'common/Typography';

import { CardVariants } from './models.d';

export const handleCardTitleProps = (cardVariant) => {
  let defaultProps: TypographyProps = {};

  const baseProps: TypographyProps = { weight: 'bold', align: 'left', color: 'mine-shaft' };

  switch (cardVariant) {
    case CardVariants.PRODUCT:
      defaultProps = {
        size: { base: 20, md: 25 },
        weight: 'regular',
        uppercase: true,
        padding: { top: 'unset', bottom: 'xxs' },
        align: 'center',
        color: 'deep-koamaru',
        lineHeight: 'small',
      };
      break;
    case CardVariants.CUP:
      defaultProps = {
        size: { base: 20, md: 25 },
        weight: 'regular',
        uppercase: true,
        padding: { top: 'unset', bottom: 'xxs' },
        align: 'center',
        color: 'deep-koamaru',
        lineHeight: 'small',
      };
      break;
    case CardVariants.BRAND:
      defaultProps = {
        size: { base: 25, md: 30, xl: 40 },
        lineHeight: 'small',
        weight: 'bold',
        align: 'center',
        color: 'white',
      };
      break;
    case CardVariants.ARTICLE_BANNER:
      defaultProps = {
        size: { base: 18, md: 25, xl: 50 },
        ...baseProps,
      };
      break;
    case CardVariants.ARTICLE_BIG:
      defaultProps = {
        size: { base: 18, md: 25 },
        ...baseProps,
      };
      break;
    case CardVariants.ARTICLE_SMALL:
      defaultProps = {
        size: { base: 18, md: 25 },
        ...baseProps,
      };
      break;
    default:
      defaultProps = {
        size: { base: 18, md: 25 },
        uppercase: true,
        padding: { top: 'md', bottom: 'md' },
        ...baseProps,
      };
      break;
  }

  return defaultProps;
};
