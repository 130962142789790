import { ButtonProps } from 'common/Button';
import { ImageType } from 'common/Image';
import { NavBaseProps } from 'common/NavBase';

type CardOptionalStringNodes = 'description' | 'descriptionConditional';

export enum CardVariants {
  BRAND = 'brand',
  PRODUCT = 'product',
  CUP = 'cup',
  ARTICLE_SMALL = 'article--small',
  ARTICLE_HORIZONTAL = 'article--horizontal',
  ARTICLE_PORTRAIT = 'article--portrait',
  ARTICLE_BIG = 'article--big',
  ARTICLE_BANNER = 'article--banner',
}

type CardButton = { label: string; image: ImageType } & NavBaseProps;

export type CardProps = {
  url: string;
  variant?: CardVariants;
  className?: string;
} & CardPropertiesType;

type CardPropertiesType = {
  variant: CardVariants;
  title: string;
  image: ImageType;
  url: string;
  button?: ButtonProps;
  color?: string;
  order?: string;
} & Partial<Record<CardOptionalStringNodes, string>>;

export type CardType = {
  id: string;
  url: string;
  card: CardPropertiesType;
  order: string;
  tags: PageContent.TagsType[];
};
